import React from "react";
import Logo from "../../assets/images/logo.png";
import "./navbar.css";
const navbar = () => {
  return (
    <div>
      <nav class="navbar navbar-expand-lg fixed-top">
        <a class="navbar-brand" href="/">
          <img src={Logo} height="50px" width="180px" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link mx-3 px-0" href="/">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link mx-3 px-0" href="/#wgpt3">
                About Us
              </a>
            </li>
            <li class="nav-item ">
              <a class="nav-link mx-3 px-0" href="/#features">
                Product & Services
              </a>
            </li>
            <li class="nav-item ">
              <a class="nav-link mx-3 px-0" href="/#gallery">
                Gallery
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link mx-3 px-0" href="/#contact">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default navbar;
