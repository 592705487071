import React from "react";
import "./testimonial.css";
const testimonial = () => {
  return (
    <div>
      <section class="testimonial text-center mt-5">
        <div class="container-fluid" id="testimonial3">
          <div
            id="testimonial4"
            class="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
            data-ride="carousel"
            data-pause="hover"
            data-interval="5000"
            data-duration="2000"
          >
            <div class="heading gradient__text">Testimonial</div>
            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active">
                <div class="testimonial4_slide">
                  <img
                    src="https://i.pinimg.com/736x/75/20/78/75207829158721eacea3d20652a0a686.jpg"
                    class="img-circle img-responsive"
                  />
                  <p>
                    Excellent team. Once again, I’m impressed with the level of
                    interaction that is made almost daily from the instructors
                    and support team. Thank you for all your help.
                  </p>
                  <h4>An employee from</h4>
                  <h4>Flipkart</h4>
                </div>
              </div>
              <div class="carousel-item">
                <div class="testimonial4_slide">
                  <img
                    src="https://www.mckinsey.com/~/media/mckinsey/our%20people/dana%20maor/dana-maor_profile_1536x1152.jpg?mw=400&car=2:2"
                    class="img-circle img-responsive"
                  />
                  <p>
                    I felt this course helped me “unwrap” another layer of R. It
                    helped me become a little more comfortable, and less afraid
                    of writing an R program.
                  </p>
                  <h4>An employee from</h4>
                  <h4>World Bank</h4>
                </div>
              </div>
              <div class="carousel-item">
                <div class="testimonial4_slide">
                  <img
                    src="https://www.niit.com/india/sites/default/files/2019-02/screen-1.jpg"
                    class="img-circle img-responsive"
                  />
                  <p>
                    The examples used in the class lessons were very helpful and
                    presented real survey problems and their solution for some
                    commonly encountered survey situations
                  </p>
                  <h4>An employee from</h4>
                  <h4>Wells Fargo</h4>
                </div>
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#testimonial4"
              data-slide="prev"
            >
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a
              class="carousel-control-next"
              href="#testimonial4"
              data-slide="next"
            >
              <span class="carousel-control-next-icon"></span>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
export default testimonial;
