import React from "react";
import Feature from "./feature/feature";
import "./aboutUs.css";
const aboutUs = () => {
  const featuresData = [
    {
      title: "Cloud computing with AWS",
      text: "Cloud computing is the on-demand delivery of IT resources over the Internet. Instead of buying, owning, and maintaining physical data centers and servers, you can access technology services, such as computing power, storage, and databases, on an as-needed basis from a cloud provider like Amazon Web Services (AWS).",
    },
    {
      title: "Elastic Search with Kibana & Logstash",
      text: "Elasticsearch is a search and analytics engine. Logstash is a server‑side data processing pipeline that ingests data from multiple sources simultaneously, transforms it, and then sends it to a 'stash' like Elasticsearch. Kibana lets users visualize data with charts and graphs in Elasticsearch.",
    },
    {
      title: "BigData with Hadoop & Spark",
      text: "Hadoop and Spark are two of the most popular processing frameworks for big data architectures. Both provide a rich ecosystem of open source technologies for preparing, processing and managing sets of big data and running analytics applications on them.",
    },
    {
      title: "Data Science /Machine Learning in Python & R",
      text: "Machine learning is a branch in computer science that studies the design of algorithms that can learn.",
    },
    {
      title: "Deep Learning (Tensor Flow), NOSQL - Redis, Neo4j",
      text: "Deep learning is a machine learning technique that teaches computers to do what comes naturally to humans: learn by example.In deep learning, a computer model learns to perform classification tasks directly from images, text, or sound.",
    },
  ];
  return (
    <div>
      <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
        <div className="gpt3__whatgpt3-feature">
          <Feature
            title="Why Us?"
            text="We are a consulting and training firm, research lab, and open source collaborative where people from diverse backgrounds come together to work on interesting projects, push themselves beyond their current capabilities, and help each other become more successful data scientists.."
          />
        </div>
        <div className="gpt3__whatgpt3-heading">
          <h1 className="gradient__text">Lectures by industry level experts</h1>
        </div>
        <div className="gpt3__whatgpt3-container">
          <Feature
            title="Our Experts"
            text="Our experts have taught in top IT company'and have 5+ years of teaching experience in terms of programming languages related to Data Science. "
          />
          <Feature
            title="Our Motivation"
            text="We at Cramlays have only one motivation which is to make the world a better place to live in"
          />
          <Feature
            title="Our Skills"
            text="Our experts have experience in top programming languages ranging from Python & R to NOSQL & Redis"
          />
        </div>
      </div>

      <div className="gpt3__features section__padding" id="features">
        <div className="gpt3__features-heading">
          <h3 className="gradient__text">Products and Services:</h3>
          <p className="gradient__text my-0">
            We give training and consultancy in the following fields by the
            forms of recorded video leactures or live e-learning{" "}
          </p>
        </div>
        <div className="gpt3__features-container">
          {featuresData.map((item, index) => (
            <Feature
              title={item.title}
              text={item.text}
              key={item.title + index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default aboutUs;
