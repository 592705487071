import React from "react";
import "./contactUs.css";
const contactUs = () => {
  return (
    <div>
      <div className="gpt3__cta" id="contact">
        <div className="gpt3__cta-content">
          <h3>Contact us to Know More</h3>
        </div>
        <div className="gpt3__cta-btn">
          <button type="button">
            {" "}
            <a href="mailto:nitingujral@cramlays.com">Contact Us</a>
          </button>
        </div>
      </div>
    </div>
  );
};
export default contactUs;
