import React from "react";
import ai from "../../assets/images/ai.png";
import "./slider.css";
const slider = () => {
  return (
    <div>
      <div className="gpt3__header section__padding mt-3" id="home">
        <div className="gpt3__header-content">
          <h1 className="gradient__text">
            Consulting & Training <br />
          </h1>
          <p>
            We are a training and a consultancy firm, to provide you with the
            skills of top programming languages in the field of Data
            Engineering, Data Science and Cloud
          </p>

          <div className="gpt3__header-content__input">
            <a href="/#wgpt3" style={{ width: "50%" }}>
              <button type="button">Get Started</button>
            </a>
          </div>
        </div>

        <div className="gpt3__header-image">
          <img src={ai} />
        </div>
      </div>
      <div className="container">
        <div className="row r1">
          <div className="col-4 col-md-2 company text-center">Amazon</div>
          <div className="col-4 col-md-2 company text-center">PwC</div>
          <div className="col-4 col-md-2 company text-center">Wells Fargo</div>
          <div className="col-4 col-md-2 company text-center hidden">Visa</div>
          <div className="col-4 col-md-2 company text-center hidden">
            World Bank
          </div>
          <div className="col-4 col-md-2 company text-center hidden">
            Qualcomm
          </div>
        </div>
      </div>
    </div>
  );
};
export default slider;
