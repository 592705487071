import React from "react";
import Navbar from "../../component/navbar/navbar";
import Slider from "../../component/slider/slider";
import AboutUs from "../../component/aboutUs/aboutUs";
import Testimonial from "../../component/testimonial/testimonial";
import Gallery from "../../component/gallery/gallery";
import ContactUs from "../../component/contactUs/contactUs";
import Footer from "../../component/footer/footer";
const home = () => {
  return (
    <div>
      <Navbar />
      <Slider />
      <AboutUs />
      <Testimonial />
      <Gallery />
      <ContactUs />
      <Footer />
    </div>
  );
};
export default home;
