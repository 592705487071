import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import "./gallery.css";

const gallery = () => {
  const images = [
    {
      src: `https://media-exp1.licdn.com/dms/image/C4E2DAQFdylvsgJDcaQ/profile-treasury-image-shrink_800_800/0/1637410932440?e=1643810400&v=beta&t=v45qjNdbqkj0RjpflTIoZuV6ILmX5eUJojoZ2_VYjak`,
    },
    {
      src: `https://media-exp1.licdn.com/dms/image/C4E2DAQF-YfOSw-D08g/profile-treasury-image-shrink_800_800/0/1637411030393?e=1643810400&v=beta&t=FJIy-JQal_foYIyf18rLS3mSvJyBv7LayQsRj3SuvMs`,
    },
    {
      src: `https://media-exp1.licdn.com/dms/image/C4E2DAQFo2wxdw-keUA/profile-treasury-image-shrink_800_800/0/1637410893352?e=1643810400&v=beta&t=NtXMaviB-fyQfeDoHPq1UR4hUAJwzOLGmedEP7q5sTU`,
    },
    {
      src: `https://media-exp1.licdn.com/dms/image/C4E2DAQGU494UogYXXA/profile-treasury-image-shrink_800_800/0/1637411317316?e=1643810400&v=beta&t=fVTlLuGpKCqwgu40pwqEKxtJEr62zpMnT6bVRhbihaI`,
    },
    {
      src: `https://media-exp1.licdn.com/dms/image/C4E2DAQHbIfptFUerTg/profile-treasury-image-shrink_800_800/0/1637410868175?e=1643810400&v=beta&t=cSIbxHVCmYvlCe72QIxUNn9M1g1x12rVXtaPza3Mku0`,
    },
    {
      src: `https://media-exp1.licdn.com/dms/image/C512DAQH74vnso2eEqQ/profile-treasury-image-shrink_800_800/0/1601614645928?e=1643810400&v=beta&t=BeaRrp2KOaIZzFqEATdzAZK0_Gp77D8GCDBS9_TMiss`,
    },
    {
      src: `https://media-exp1.licdn.com/dms/image/C4E2DAQE-emm2AURCSQ/profile-treasury-image-shrink_800_800/0/1597789810062?e=1643810400&v=beta&t=AS7qXOuQuSl4my0cMtReSSlKyko8AR6yNNAoqDTRP_w`,
    },
    {
      src: `https://media-exp1.licdn.com/dms/image/C4E2DAQF86gJoOWiSqg/profile-treasury-image-shrink_800_800/0/1597821292994?e=1643810400&v=beta&t=JUQVi-_G6PPOwx3uR8EvCkiz1AQr83JfQxb6LMcew_A`,
    },
    {
      src: `https://media-exp1.licdn.com/dms/image/C4E2DAQG7mrlCsb60Cg/profile-treasury-image-shrink_800_800/0/1601624046691?e=1643810400&v=beta&t=BzPOf8aNNa9XhW-T3xE_y0eAZjLs2QBsvrKRMT9wXzo`,
    },
  ];

  return (
    <div>
      <div className="pt3__whatgpt3 section__margin my-5" id="#gallery">
        <div className="row">
          <div class="heading gradient__text ">
            Photos from our Live classes
          </div>
          <div className="col-12 ">
            <Carousel
              images={images}
              style={{ height: 600 }}
              hasMediaButton="false"
              hasSizeButton="false"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default gallery;
